body.kcBodyClass {
  background: white;
}

.card-pf {
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  box-shadow: none;
  border-color: white;
  border-top: none
}